<!-- @Author: Yu_Bo -->
<template>
    <div class='ai_painting'>
        <div class="ai_painting_banner">
            <div class="banner_l">
                <div class="tit">
                    AI背景
                </div>
                <div class="desc">
                    根据您的灵感绘画，人工智能加持，助力脑洞大开，尽情创作～
                </div>
            </div>
            <div class="banner_r">
                <p class="text">剩余绘画张数：<span class="num">{{ drawing }}</span>张</p>
                <div class="btn" @click="toBuy">订阅</div>
            </div>
        </div>
        <div class="ai_painting_main">
            <!-- 左侧 -->
            <div class="ai_painting_main_l">
                <!-- 创意描述 -->
                <div class="ai_top">
                    <div class="ai_title">
                        <span>创意描述</span>
                        <el-tooltip placement="right">
                            <div slot="content">
                                创意描述以短句、短语为佳，画面风格和艺术家的参数设计会对生成有艺术修饰作用，<br />
                                可添加颜色、光线、细节程度描写，支持中英文语言输入，英文描述更加精准哦～
                            </div>
                            <img src="@/assets/images/workben/question.png" alt="">
                        </el-tooltip>
                    </div>
                </div>
                <div class="ai_describe mt20">
                    <el-input type="textarea" placeholder="请输入内容，最多500字" v-model="originality" :rows="6" maxlength="500" show-word-limit>
                    </el-input>
                </div>
                <!-- 灵感引用 -->
                <div class="ai_top mt30">
                    <div class="ai_title">
                        <span>灵感引用</span>
                    </div>
                    <div class="ai_top_right" @click="getInsList">
                        <img class="refresh" src="@/assets/images/workben/refresh.png" alt="">
                        <span>换一批</span>
                    </div>
                </div>
                <div class="quote_box  mt20">
                    <div class="quote_list" :style="{ height: (number * 54) + 'px' }">
                        <div class="quote_item" v-for="item, index in quoteList.slice(0, number)" :key="index" @click="selectInspiration(item.content)">{{ item.content }}</div>
                    </div>
                    <div class="btn" @click="changeQuoteList">
                        <span>{{ quoteShow ? '收起' : '展开' }}</span>
                        <i class="el-icon-arrow-up" :class="{ rotate: !quoteShow }"></i>
                    </div>
                </div>
                <!-- 参考图片 -->
                <div class="ai_top mt30">
                    <div class="ai_title">
                        <span>参考图片</span>
                        <el-tooltip placement="right">
                            <div slot="content">
                                您可以上传1张参考图片
                            </div>
                            <img src="@/assets/images/workben/question.png" alt="">
                        </el-tooltip>
                    </div>
                </div>
                <div class="ai_picture mt20">
                    <upload-draw @uploadSuccess="uploadSuccess" :url="url"></upload-draw>
                    <div class="ai_picture_r">
                        <div class="text">相似度：<span>{{ reference_rate }}%</span></div>
                        <el-slider v-model="reference_rate" :show-tooltip="false"></el-slider>
                    </div>
                </div>
            </div>
            <!-- 右侧 -->
            <div class="ai_painting_main_r">
                <!-- 画面风格 -->
                <div class="ai_top">
                    <div class="ai_title">
                        <span>画面风格</span>
                        <el-tooltip placement="right">
                            <div slot="content">
                                选择不同风格修饰会有不同的画面效果，部分细节修饰可提升画面质量
                            </div>
                            <img src="@/assets/images/workben/question.png" alt="">
                        </el-tooltip>
                    </div>
                    <div class="ai_top_right" @click="styleShow = !styleShow">
                        <span>{{ styleShow ? '收起' : '展开' }}</span>
                        <img class="arrow" :class="{ rotate: !styleShow }" src="@/assets/images/workben/arrow.png" alt="">
                    </div>
                </div>
                <div class="style_list" :style="{ height: styleShow ? '312px' : '104px' }">
                    <div class="style_item" :class="item.checked ? 'active' : ''" v-for="(item, index) in styleList" :key="index" @click="selectStyle(item, index)">
                        <img class="cover" :src="item.picture" alt="">
                        <div class="txt">{{ item.name }}</div>
                        <img class="check-img" src="@/assets/images/creation/creationMng_(72).png" alt="" v-if="item.checked">
                    </div>
                </div>
                <!-- 艺术家 -->
                <div class="ai_top mt30">
                    <div class="ai_title">
                        <span>艺术家</span>
                        <el-tooltip placement="right">
                            <div slot="content">
                                选择对应艺术家达到对应艺术效果
                            </div>
                            <img src="@/assets/images/workben/question.png" alt="">
                        </el-tooltip>
                    </div>
                    <div class="ai_top_right" @click="artistShow = !artistShow">
                        <span>{{ artistShow ? '收起' : '展开' }}</span>
                        <img class="arrow" :class="{ rotate: !artistShow }" src="@/assets/images/workben/arrow.png" alt="">
                    </div>
                </div>
                <div class="style_list" :style="{ height: artistShow ? '312px' : '104px' }">
                    <div class="style_item" :class="item.name == artist ? 'active' : ''" v-for="(item, index) in artistList" :key="index" @click="selectArtist(item)">
                        <img class="cover" :src="item.picture" alt="">
                        <div class="txt">{{ item.name }}</div>
                        <img class="check-img" src="@/assets/images/creation/creationMng_(72).png" alt="" v-if="item.name == artist">
                    </div>
                </div>
                <!-- 生成数量 -->
                <div class="ai_top mt20">
                    <div class="ai_title">
                        <span>生成数量</span>
                        <el-tooltip placement="right">
                            <div slot="content">
                                作品数量会消耗同等数量的作画次数
                            </div>
                            <img src="@/assets/images/workben/question.png" alt="">
                        </el-tooltip>
                    </div>
                    <div class="ai_top_right" @click="settingShow = !settingShow">
                        <span>{{ settingShow ? '收起设置' : '展开设置' }}</span>
                        <img class="arrow" :class="{ rotate: !settingShow }" src="@/assets/images/workben/arrow.png" alt="">
                    </div>
                </div>
                <div class="mt14">
                    <el-input-number v-model="num" controls-position="right" :min="0" :max="10"></el-input-number>
                </div>
                <div class="proportion mt20" :style="{ height: settingShow ? '70px' : '0' }">
                    <!-- 画面比例 -->
                    <div class="ai_top">
                        <div class="ai_title">
                            <span>画面比例</span>
                            <el-tooltip placement="right">
                                <div slot="content">
                                    人物类的图片推荐使用1:1的方图，景观类的图片推荐使用其它的比例尺寸
                                </div>
                                <img src="@/assets/images/workben/question.png" alt="">
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="proportion_list mt14">
                        <div class="proportion_item" :class="{ active: item.value == proportion }" v-for="item in proportionList" :key="item.value" @click="proportion = item.value">
                            <div class="block">

                                <img :src="item.label" alt="">
                            </div>
                            <div class="text">{{ item.value }}</div>
                        </div>
                    </div>
                </div>
                <div class="ai_bottom mt30">
                    <div class="bottom_btn" @click="draw">
                        生成绘画 ·<span>消耗{{ num }}次</span>
                    </div>
                </div>
            </div>
        </div>
        <loading ref="loading" :type="'2'" @paintingParents="paintingParents"></loading>
        <painting-success ref="paintingSuccess" @resetPage="resetPage" @reCreate="reCreate"></painting-success>
        <no-draw-times ref='dialogDraw'></no-draw-times>
        <no-draw-times ref='dialogDraw' :title="'您还没有绘画次数'" :type="2" @renovate="getParams"></no-draw-times>
    </div>
</template>

<script>
import Loading from '../components/loading/index.vue' //加载
import PaintingSuccess from '../components/painting_success/index.vue'  //成功
import NoDrawTimes from '@/components/trial_expiration/no_draw_times.vue'//没有绘画次数
import UploadDraw from '@/components/upload/uploadDraw.vue'//上传
export default {
    components: { Loading, PaintingSuccess, NoDrawTimes, UploadDraw },
    data() {
        return {
            styleShow: false,   //风格展开
            artistShow: false,  //艺术家展开
            settingShow: true,  //设置展开
            quoteShow: false,
            num: 1,   // 生成数量 最大10
            originality: '', // 创意描述
            reference_pic: '', // 参考图片 （用不含域名的 地址 对应 short_url）
            reference_rate: 0, // 参考图片相似度
            style: [], // 风格
            artist: '', // 艺术家
            proportion: 0, // 画面大小
            proportionList: [],
            quoteList: [],
            artistList: [], // 艺术家
            styleList: [], // 风格
            number: 3,
            page: 0,
            drawing: 0, // 绘画余额
            info: {},
            url: '',  //展示参考图片
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getParams()
        this.getInsList()
        if (this.$route.query.id) {
            this.getInfo(this.$route.query.id)
        }
    },
    mounted() { },
    methods: {
        // 成功弹窗
        paintingParents() {
            // this.$refs.paintingSuccess.open(this.info)
        },
        // 绘制
        draw() {
            if (this.drawing >= this.num) {
                this.createPainting()
            } else {
                this.noNumber()
            }
        },
        // 生成中  加载弹窗
        createPainting() {
            let style = this.styleList.filter(i => i.checked)
            this.style = style.map(item => item.name)

            const data = {
                num: this.num,   // 生成数量 最大10
                originality: this.originality, // 创意描述
                reference_pic: this.reference_pic, // 参考图片 （用不含域名的 地址 对应 short_url）
                reference_rate: this.reference_rate, // 参考图片相似度
                style: this.style, // 风格
                artist: this.artist, // 艺术家
                proportion: this.proportion, // 画面大小
                order_source: 1, //订单来源 1 PC 2 APP
            }
            console.log(data)
            this.$refs.loading.open()

            // return
            this.$creationApi.addDraw(data).then(res => {
                if (res.code == 1000) {
                    this.$refs.loading.close()
                    this.$refs.paintingSuccess.open(res.result)

                    console.log(res.result)
                    // this.info = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })

        },
        // 没有次数
        noNumber() {
            this.$refs.dialogDraw.open()
        },
        // 展开灵感
        changeQuoteList() {
            this.quoteShow = !this.quoteShow
            if (this.number == 3) {
                this.number = this.quoteList.length
            } else {
                this.number = 3
            }
        },
        uploadSuccess(short_url, url) {
            this.reference_pic = short_url
            this.url = url
        },
        getParams() {
            this.$creationApi.getDrawParams().then(res => {
                if (res.code == 1000) {
                    this.proportionList = res.result.proportion
                    this.artistList = res.result.artist
                    this.styleList = res.result.style
                    this.drawing = res.result.balance.drawing
                    this.styleList.forEach(item => {
                        this.$set(item, "checked", false)

                    })
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 灵感
        getInsList() {
            const data = {
                page: this.page + 1,
                limit: 6,
            }
            this.$creationApi.inspirationList(data).then(res => {
                if (res.code == 1000) {
                    this.quoteList = res.result.data
                    if (res.result.total % 6 != 0) {
                        this.page = 0
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })

        },
        // 灵感
        selectInspiration(val) {
            this.originality = val
        },
        selectStyle(item, index) {
            // if (this.styleList.map(i => i.checked).length > 10) {
            //     return
            // }
            this.$set(item, "checked", !item.checked)

        },
        selectArtist(item) {
            this.artist = item.name
        },
        // 新增 重置页面
        resetPage() {
            this.num = 1   // 生成数量 最大10
            this.originality = '' // 创意描述
            this.reference_pic = '' // 参考图片 （用不含域名的 地址 对应 short_url）
            this.reference_rate = 10 // 参考图片相似度
            this.style = [] // 风格
            this.styleList = []
            this.artist = '' // 艺术家
            this.proportion = 0 // 画面大小
        },
        // 重新生成
        reCreate() {
            this.getParams()
            this.draw()
        },
        getInfo(id) {
            this.$digitalApi.drawDetails(id).then(res => {
                if (res.code == 1000) {
                    let obj = res.result
                    this.artist = obj.artist;
                    this.style = obj.style;
                    this.num = obj.num;
                    this.proportion = obj.proportion;
                    this.reference_rate = obj.reference_rate;
                    this.url = obj.reference_pic;
                    this.reference_pic = obj.reference_pic;
                    this.originality = obj.originality;
                    // 回显风格
                    this.styleList.forEach(item => {
                        if (this.style.indexOf(item.name) >= 0) {
                            this.selectStyle(item)
                        }
                    });
                }
            })
        },
        toBuy() {
            this.noNumber()
        }
    },
}
</script>

<style lang='scss' scoped>
.ai_painting {
    min-width: 1774px;

    .ai_painting_banner {
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: space-between;
        padding: 0 200px 0 50px;
        background: url(~@/assets/images/workben/banner1.png) no-repeat 100% /
            cover;
        border-radius: 10px;

        .banner_l {
            .tit {
                font-size: 32px;
                font-weight: 500;
                color: #ffffff;
                line-height: 45px;
                padding-top: 33px;
            }

            .desc {
                font-size: 14px;
                color: #ffffff;
                line-height: 20px;
                padding-top: 10px;
            }
        }

        .banner_r {
            padding: 0 14px;
            height: 50px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 25px;
            margin-top: 45px;
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
                font-size: 14px;
                color: #ffffff;
                line-height: 20px;
                padding-right: 16px;

                .num {
                    font-size: 24px;
                }
            }

            .btn {
                width: 56px;
                height: 30px;
                background: linear-gradient(90deg, #4875ff 0%, #2b4afe 100%);
                border-radius: 15px;
                display: flex;
                text-align: center;
                justify-content: center;
                cursor: pointer;
                color: #ffffff;
                font-size: 14px;
                line-height: 30px;
            }
        }
    }

    .ai_painting_main {
        width: 100%;
        height: 833px;
        background: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        display: flex;
        padding: 30px 50px;

        .ai_top {
            display: flex;
            justify-content: space-between;

            .ai_title {
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                display: flex;
                align-items: center;

                span {
                    padding-right: 6px;
                }

                img {
                    width: 14px;
                    height: 14px;
                }
            }

            .ai_top_right {
                display: flex;
                align-items: center;
                cursor: pointer;

                .refresh {
                    width: 14px;
                    height: 14px;
                    margin-right: 4px;
                }

                span {
                    font-size: 14px;
                    color: #2e4bf2;
                }

                .arrow {
                    width: 10px;
                    height: 10px;
                    margin-left: 4px;
                }

                .rotate {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    transition-duration: 0.3s;
                }
            }
        }

        .ai_painting_main_l {
            width: 558px;
            margin-right: 120px;

            .quote_box {
                width: 558px;
                background: #f5f7fa;
                padding: 16px 14px;
                border-radius: 4px;

                .quote_list {
                    overflow: hidden;
                    transition: height 0.3s ease;

                    .quote_item {
                        width: 530px;
                        height: 44px;
                        background: #ffffff;
                        box-shadow: 0px 2px 7px 0px rgba(234, 234, 234, 0.5);
                        border-radius: 4px;
                        border: 1px solid #e2e2e2;
                        padding: 12px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow: ellipsis;
                        color: #666666;
                        font-size: 14px;
                        margin-bottom: 10px;
                        transition: height 0.3s ease;
                        cursor: pointer;

                        &:hover {
                            color: $blueColor1;
                            border-color: $blueColor1;
                        }
                    }
                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    span {
                        font-size: 14px;
                        color: #666666;
                        padding-right: 2px;
                    }

                    .el-icon-arrow-up {
                        font-size: 14px;
                        color: #666666;
                    }

                    .rotate {
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        transition-duration: 0.3s;
                    }
                }
            }

            .ai_picture {
                display: flex;
                justify-content: space-between;

                .ai_picture_l {
                    width: 90px;
                    height: 90px;
                    background: #f5f7fa;
                    border-radius: 4px;
                    border: 1px dashed #dddfe6;
                    text-align: center;
                    color: #2e4bf2;
                    cursor: pointer;

                    i {
                        font-size: 32px;
                        font-weight: 500;
                        margin-top: 16px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 17px;
                        margin-top: 6px;
                    }
                }

                .ai_picture_r {
                    width: 448px;
                    height: 90px;
                    background: #f5f7fa;
                    border-radius: 4px;
                    padding: 20px;

                    .text {
                        font-size: 14px;
                        color: #666666;
                        line-height: 20px;

                        span {
                            padding-left: 6px;
                            color: #2e4bf2;
                        }
                    }
                }
            }
        }

        .ai_painting_main_r {
            width: 812px;

            .style_list {
                width: 812px;
                display: flex;
                flex-wrap: wrap;
                transition: height 0.3s ease;
                overflow: hidden;

                .style_item {
                    width: 84px;
                    height: 84px;
                    border-radius: 4px;
                    margin-top: 20px;
                    margin-right: 20px;
                    position: relative;
                    border: 1px solid transparent;
                    cursor: pointer;

                    .cover {
                        width: 84px;
                        height: 84px;
                        border-radius: 4px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: cover;
                    }

                    .txt {
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        // color: #FFFFFF;
                        position: absolute;
                        left: 0;
                        bottom: 10px;
                        width: 84px;
                        text-align: center;
                    }

                    .check-img {
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        width: 17px;
                        height: 17px;
                    }
                }

                .style_item:nth-child(8n) {
                    margin-right: 0;
                }

                .style_item.active {
                    border: 1px solid $blueColor1;
                }
            }

            .proportion {
                overflow: hidden;
                transition: height 0.1s ease;

                .proportion_list {
                    display: flex;

                    .proportion_item {
                        width: 80px;
                        height: 30px;
                        background: rgba(188, 188, 188, 0.1);
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 14px;
                        cursor: pointer;

                        .block {
                            width: 15px;
                            height: 15px;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }

                        .text {
                            font-size: 12px;
                            color: #999999;
                            padding-left: 4px;
                        }
                    }

                    .proportion_item.active {
                        background: rgba(46, 75, 242, 0.1);

                        .text {
                            color: #2e4bf2;
                        }
                    }
                }
            }

            .ai_bottom {
                display: flex;
                justify-content: flex-end;

                .bottom_btn {
                    width: 156px;
                    height: 38px;
                    background: linear-gradient(
                        90deg,
                        #4875ff 0%,
                        #2b4afe 100%
                    );
                    border-radius: 2px;
                    line-height: 38px;
                    text-align: center;
                    font-size: 14px;
                    color: #ffffff;
                    cursor: pointer;

                    span {
                        font-size: 12px;
                        opacity: 0.6;
                        padding-left: 6px;
                    }
                }
            }
        }
    }
}

.mt14 {
    margin-top: 14px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}
</style>